<template>
  <div>
    <va-card>
      <template slot="actions">
        <va-button color="gray" icon="entypo entypo-left-open" @click="back()">Back</va-button>
     <!--    <va-button color="orange" icon="entypo entypo-export" @click="exportData()">PDF</va-button> -->
      </template>
        <h3>Customer Organization Name  </h3>
        <p>{{customer.customer_org_name}}</p>
    </va-card><br>
    <va-card>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        style="height: 52vh;"
        no-pagination>
        <template slot="total" slot-scope="props">
            <h5>{{getRowTotal(props.rowData)}}</h5>
        </template>
      </va-data-table>
      <div class="grid" v-if="filteredData.length">
        <dir><h1>Total</h1></dir>
        <div style="margin-top: 15px;"><h1>{{otc_total}}</h1></div>
        <div style="margin-top: 15px;"><h1>{{subcription_total}}</h1></div>
        <div style="margin-top: 15px;"><h1>{{final_total}}</h1></div>
      </div>
    </va-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'

Vue.use(vueResource)
export default {
  created () {
    this.payment_id = this.$route.params.id
    this.getAllCustomer()
  },
  data () {
    return {
      customer: {},
      payment_id: '',
      otc_total: 0,
      subcription_total: 0,
      final_total: 0,
      customer_org_pts: [],
    }
  },
  methods: {
    getRowTotal(data){
        return parseInt(data.otc_license)+parseInt(data.subscription_cost)
    },
    back () {
      this.$router.push({ name: 'license_request' })
    },
    getAllCustomer () {
      this.$http.get(`${config.menu.host}customer_details/${this.payment_id}`)
        .then(res => {
          res = res.data
          this.customer_org_pts = res
          this.customer_org_pts.map(
            (customer_org_pt, i) => {
              this.otc_total += parseInt(customer_org_pt.otc_license)
              this.subcription_total += parseInt(customer_org_pt.subscription_cost)
              return customer_org_pt.sno = ++i
            },
          )
          this.final_total = this.otc_total + this.subcription_total
          this.customer = this.customer_org_pts.length && this.customer_org_pts[0]
        })
    },
    exportData () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(`${config.menu.host}customer_details/${this.customer_org_id}/pdf`)
      .then(res => {
        loader.hide()
        var link = document.createElement('a')
        var filename = res.data.file_name + '.pdf'
        var url = 'data:text/pdf;base64,' + escape(res.data.payload)
        link.href = url
        link.download = filename
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch(error => {
        loader.hide()
        Vue.notify({ text: error.body, type: 'error'})
      })
    },
  },
  computed: {
    fields () {
      return [
        {
          name: 'sno',
          width: '8%',
        }, {
          name: 'platform_name',
          title: 'Platforms',
          width: '20%',
        }, { 
          name: 'otc_license',
          title: 'otc license',
          width: '30%',
        }, {
          name: 'subscription_cost',
          title: 'subscription cost',
          width: '30%',
        },{
          name: '__slot:total',
          title: 'Total',
          width: "20%"
        }, 
      ]
    },
    filteredData () {
      const filteredCus = search(this.term, this.customer_org_pts)
      return filteredCus
    },
  },

}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;
  background-color: rgba(0, 128, 0, 0.212);
  border-radius: 0.5rem;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#td,
#th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

#td_ {
  text-align: left;
  padding: 8px;
}

#tr:nth-child(even) {
  background-color: #dddddd;
}

#invoice {
  font-weight: bolder;
}

</style>
